import { Grid, Paper, Stack, Typography } from "@mui/material";
import LinkButton from "../../../components/Buttons/LinkButton";
import Section from "../../../components/Section";
import OpaqueContainer from "../../../components/Section/OpaqueContainer";
import { Page } from "../../../types";
import portrait from "../../../img/portrait.jpg";

const ShortAboutMe = () => {
  return (
    <Section
      img="beach2.jpg
"
    >
      <Grid container spacing={2}>
        <Grid item maxWidth={"50%"} md={6}>
          <Stack justifyContent={"center"} height={"100%"} marginBottom="-7px">
            <Paper sx={{ overflow: "hidden", marginBottom: "-7px" }}>
              <img
                src={portrait}
                alt={"vincent_mcmahon"}
                style={{ width: "auto", maxWidth: "100%" }}
              />
            </Paper>
          </Stack>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Stack justifyContent={"center"} height={"100%"}>
            <OpaqueContainer>
              <Stack spacing={2}>
                <Typography variant={"h4"}>Vincent McMahon</Typography>
                <Typography>
                  Hello! I'm Vincent, an independent civil celebrant based in
                  Milngavie, serving Greater Glasgow and beyond. I enjoy
                  providing personalized ceremonies that honour life's important
                  moments. Whether it's a wedding, funeral, or naming ceremony,
                  I'm here to help you create a meaningful and memorable
                  experience.
                </Typography>
                <LinkButton page={Page.AboutMe}>Learn More About Me</LinkButton>
              </Stack>
            </OpaqueContainer>
          </Stack>
        </Grid>
      </Grid>
    </Section>
  );
};

export default ShortAboutMe;
