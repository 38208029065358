import { Snackbar, Button, Alert, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { ContactResponse, ContactResponseState } from "./types";
import { CONTACT_EMAIL, CONTACT_PHONE } from "../../constants";

const ContactSnackbar = ({
  contactResponse,
  setContactResponse,
}: {
  contactResponse: ContactResponseState;
  setContactResponse: React.Dispatch<
    React.SetStateAction<ContactResponseState>
  >;
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!contactResponse);
  }, [contactResponse]);

  const handleClose = useCallback(() => {
    setContactResponse(undefined);
    setOpen(false);
  }, [setContactResponse]);

  const success = contactResponse === ContactResponse.Success;

  return (
    <Snackbar
      open={contactResponse && open}
      autoHideDuration={success ? 6000 : undefined}
      onClose={handleClose}
      action={
        <Button color="inherit" onClick={handleClose}>
          Close
        </Button>
      }
    >
      <Alert
        onClose={handleClose}
        severity={contactResponse}
        sx={{ width: "100%" }}
      >
        {success ? (
          <Typography>Thank you for reaching out!</Typography>
        ) : (
          <Typography>There was an error with the contact form.</Typography>
        )}
        {success
          ? "Ill get back to you a soon as possible."
          : `Please try again later or you can reach me at ${CONTACT_PHONE} or ${CONTACT_EMAIL}.`}
      </Alert>
    </Snackbar>
  );
};

export default ContactSnackbar;
