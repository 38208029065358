import { Divider, Typography } from "@mui/material";
import InfoSection from "../../components/Section/InfoSection";

import PriceHeader from "../../components/Section/InfoSection/PriceHeader";
import PageWrapper from "../../components/PageWrapper";

const FuneralsPage = () => {
  return (
    <PageWrapper>
      <InfoSection title="Funerals" buttonText="Contact Me For Support">
        <Typography>
          Saying goodbye to a loved one is an emotionally challenging time. It
          is a privilege and an honour to lead a funeral. It's my role to lend a
          professional guiding hand to help you through the process. Each
          funeral is a unique tribute to a unique person. It may be a
          celebration of life but is always written and delivered with
          sensitivity and compassion which truly reflects the life lost. When
          creating a funeral ceremony, I take time and great care to listen to
          you. In doing so, it is possible to understand the person who has
          passed, to understand those personal traits that people wish to
          remember and to recall the most memorable moments of their life. Time
          is taken to reflect on the special relationships that they held dear
          and to celebrate their life. As an independent civil celebrant, I am
          able to be respectfully inclusive of all the important aspects of your
          loved one's life, beliefs, and passions. A ceremony may have a secular
          feel if religion was not important to the person we are saying our
          goodbye's to. I am more than happy to include any religious readings,
          prayers or hymns into your ceremony if this is important to you and
          your loved one.
          <br />
          <br />
          In designing and delivering a ceremony with compassion and dignity, I
          hope to leave you with a memory that will, in the fullness of time, be
          comforting to you and your loved ones.
          <br />
          <br />
          Planning the funeral of a loved one is never easy and it can feel
          quite overwhelming. There are so many things to arrange and it comes
          at a time where you are already managing so much. My commitment to you
          is to be as helpful as I can be. I work with your chosen funeral
          director and together we become a team around you. I am there to help
          support you and to deliver a beautiful and personal ceremony.
          <br />
          <br />
          Whether you are looking for a traditional graveside or crematorium
          ceremony, or a less traditional funeral ceremony such as natural
          burial ground, for example, I create the ceremony based on the time we
          have spent together thinking about your loved one and to blend
          seamlessly with your chosen venue and style of ceremony.
          <br />
          <br />
          It can be hard to fully do justice a life lived in a fixed time slot.
          You may wish to continue the celebration of life beyond the committal
          ceremony. The funeral wake is often a safe space where people continue
          to share stories and pay tribute to the deceased. I am happy to help
          you think about how you can carry some of the ceremony over into the
          wake so that you feel you have had space you need to say your final
          farewells without external time constraints.
          <br />
          <br />
          If you are having a cremation and have decided upon interment or
          scattering of the ashes, I am happy to join you and offer a blessing,
          or prayer, and carefully chosen parting words as part of my celebrant
          service.
        </Typography>

        <Divider />

        <PriceHeader serviceName={"Funerals"} />

        <Typography>
          <ul>
            <li>Funeral Celebrant Service. £220.00</li>
            <li>
              Initial 'getting to know you' face to face meeting. I will visit
              to talk with you about the person who has passed. I try to make
              the meeting as informal as possible. It should feel like a gentle
              conversation and not an interview. It is common for people to
              experience a range of emotions during this meeting. It is a safe
              space and I am very experienced working with people in distressing
              situations. We often have laughter and tears. Many of the families
              and individuals that I have worked with describe this meeting as a
              really 'comforting' and 'helpful' experience. The information that
              you give me helps me to put together a truly meaningful reflection
              of the person who has passed. It is important that the ceremony is
              right for you, your family and friends and that it makes the
              experience of saying goodbye, as good as it can possibly be under
              the circumstances.
            </li>
            <li>
              Access to a large library of readings and poems. This in no way
              prevents you from adding your own choices and these will be
              incorporated into the ceremony.
            </li>
            <li>
              Contact via telephone, email or text to any people identified by
              you as important to the ceremony. It may be that they are
              delivering readings or carrying the coffin etc. I will talk them
              through the ceremony and support them in writing any reading if
              they so desire.
            </li>
            <li>
              The writing of the ceremony and a first draft copy. Amendments as
              necessary.
            </li>
            <li>
              If required, an order of service for you or your funeral director
              to use in the printed booklets.
            </li>
            <li>Delivering the funeral ceremony at a venue of your choice.</li>
            <li>Liaising with your funeral director.</li>
          </ul>
        </Typography>
      </InfoSection>
    </PageWrapper>
  );
};

export default FuneralsPage;
