import { Stack, Typography } from "@mui/material";
import LinkButton from "../../../components/Buttons/LinkButton";
import Section from "../../../components/Section";
import OpaqueContainer from "../../../components/Section/OpaqueContainer";
import logo from "../../../img/logo.png";

const TitleSection = () => {
  return (
    <Section titleSection img="beach.jpg">
      <OpaqueContainer>
        <Stack>
          <img
            src={logo}
            alt={"vm_celebrant_logo"}
            style={{
              margin: "auto",
              width: "262px",
              maxWidth: "100%",
            }}
          />
          <Typography
            display={{ xs: "none", sm: "block" }}
            variant={"h2"}
            className="text-center"
          >
            Celebrating Life's Journeys...
          </Typography>
          <Typography
            display={{ xs: "block", sm: "none" }}
            variant={"h5"}
            className="text-center"
          >
            Celebrating Life's Journeys...
          </Typography>

          <Typography
            className="text-center"
            style={{
              lineHeight: "38px",
              fontWeight: "500",
              marginTop: 10,
            }}
          >
            From the joyful union of marriage to the tender moments of farewell,
            and every cherished milestone in between, I am here to help you
            craft meaningful ceremonies that honor your unique story. Let’s
            celebrate the beauty of life, love, and remembrance together.
          </Typography>
          <LinkButton />
        </Stack>
      </OpaqueContainer>
    </Section>
  );
};

export default TitleSection;
