import { Button, Stack } from "@mui/material";
import { PropsWithChildren } from "react";
import { Page } from "../../types";
import { useNavigate } from "react-router-dom";

const LinkButton = ({
  page = Page.Contact,
  children,
}: PropsWithChildren<{ page?: Page }>) => {
  const navigate = useNavigate();

  return (
    <Stack direction="row" justifyContent="center" width="100%">
      <Button
        onClick={() => {
          navigate(`/${page}`);
        }}
        variant="outlined"
      >
        {children ? children : "Contact"}
      </Button>
    </Stack>
  );
};

export default LinkButton;
