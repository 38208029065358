import PageWrapper from "../../components/PageWrapper";
import ServiceCardsSection from "./ServiceCardsSection";

import TitleSection from "./TitleSection";
import ShortAboutMe from "./ShortAboutMe";

const HomePage = () => {
  return (
    <PageWrapper>
      <TitleSection />
      <ServiceCardsSection />
      <ShortAboutMe />
    </PageWrapper>
  );
};

export default HomePage;
