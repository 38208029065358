import { Stack, Typography } from "@mui/material";
import Section from "..";
import { PropsWithChildren } from "react";
import OpaqueContainer from "../OpaqueContainer";
import LinkButton from "../../Buttons/LinkButton";

const InfoSection = ({
  title,
  buttonText,
  children,
}: PropsWithChildren<{ title: string, buttonText: string }>) => {
  return (
    <>
      <Section titleSection img="beach.jpg">
        <OpaqueContainer opaque>
          <Typography variant="h3" className="text-center">
            {title}
          </Typography>
        </OpaqueContainer>
      </Section>
      <Section>
        <Stack spacing={2}>
          {children}
          <LinkButton>{buttonText}</LinkButton>
        </Stack>
      </Section>
    </>
  );
};

export default InfoSection;
