import { Grid, Stack, Typography } from "@mui/material";
import InfoSection from "../../components/Section/InfoSection";

import portrait from "../../img/long_portrait.jpg";
import PageWrapper from "../../components/PageWrapper";

const AboutMePage = () => {
  return (
    <PageWrapper>
      <InfoSection title="About Me" buttonText="Contact Me">
        <Grid container>
          <Grid item maxWidth={"50%"} md={6}>
            <Stack justifyContent={"center"} height={"100%"}>
              <img
                src={portrait}
                alt={"vincent_mcmahon"}
                style={{ width: "auto", maxWidth: "100%", marginRight: 20 }}
              />
            </Stack>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography>
              My name is Vincent McMahon and I'm a qualified independent civil
              celebrant. I trained with the award-winning Association of
              Professional Celebrants. I live in Milngavie and serve East
              Dunbartonshire, West Dunbartonshire and Greater Glasgow. I'm also
              happy to travel further afield to support you with your
              celebration of life event, whether this be a funeral, wedding,
              memorial service, naming ceremony or vow renewal.
              <br />
              <br />I came to this vocation after using a celebrant for my
              mother's funeral. This was so personal and all about my mum,
              incorporating just the right amount of spirituality that we had
              asked for. Having been to many funerals in churches, this felt
              more of a celebration of mum's life and less about the particular
              religion. We were able to work closely with the celebrant to plan
              her service, which allowed us to laugh and cry and come away
              feeling that we had given her a dignified send off, while
              encompassing a real sense of her personality and her life story. I
              wanted to give other families the same experience.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Typography>
          Since then, I have realised that many people may or may not follow a
          particular faith but still want their life journeys to be celebrated
          in a way that is personal to them and their families/loved ones.
          <br />
          <br />I am also a very experienced teacher, having taught all around
          the world, but mainly in Scotland, and I'm currently a Depute Head in
          a local authority secondary school &ndash; currently, my main
          responsibility is managing two additional support need units. With
          this background, I am highly skilled in the four key areas of
          communication: listening, reading, writing and speaking and I will use
          these skills to tell your story.
          <br />
          <br />I truly love meeting people and it has been a privilege and an
          honour helping them create bespoke, memorable ceremonies for the
          significant transitions in their lives, and those of their loved ones.
          I am the guiding and supportive hand who works closely with couples
          and families to plan and deliver each ceremony and to ensure everyone
          feels safe and included.
        </Typography>
      </InfoSection>
    </PageWrapper>
  );
};

export default AboutMePage;
