import { Grid, Box, Stack, Typography } from "@mui/material";
import seal from "../../img/apc-celebrant-seal_orig.png";
import Section from "../Section";
import { CONTACT_EMAIL, CONTACT_PHONE } from "../../constants";

const Footer = () => {
  return (
    <Section bgcolor={"#efefef"}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <Box margin="auto" width="fit-content">
            <img
              src={seal}
              alt={"apc_seal"}
              style={{ width: "auto", maxWidth: "150px", margin: "auto" }}
            />
          </Box>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Stack justifyContent={'center'} height='100%'>
            <Typography>
              For doubts or guidence, contact me at
              <br />
              <br />
              Email: {CONTACT_EMAIL}
              <br />
              Phone: {CONTACT_PHONE}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Section>
  );
};

export default Footer;
