import { Divider, Typography } from "@mui/material";
import InfoSection from "../../components/Section/InfoSection";

import PriceHeader from "../../components/Section/InfoSection/PriceHeader";
import PageWrapper from "../../components/PageWrapper";

const WeddingsPage = () => {
  return (
    <PageWrapper>
      <InfoSection title="Weddings" buttonText="Start Planning Your Ceremony">
        <Typography>
          So, you are getting married. How exciting! This is your big day and
          it's important that you get the ceremony that you want. Your wedding
          ceremony celebrates your unique personalities, your story, your hopes,
          and aspirations going forward into married life, and, most
          importantly, your personal wedding vows/promises to each other. We'll
          work together to make this the best day of your lives. One that is
          uniquely you, focusing on your journey and future dreams. One that
          brings together the magic of your story, a ceremony created with you,
          for you. The legal registering of your marriage is an important, yet
          simple process. It does not have to be part of your wedding ceremony.
          This is your gift to choose. It holds equal legal standing and
          respectful gravitas however you choose to coordinate it with your
          wedding ceremony. Your celebratory celebrant-led ceremony is not
          legally binding. This is the ceremonial celebration of your marriage.
          In accordance with the marriage law of Scotland, you will both have
          been required to first give the legal notices of marriage and to have
          completed the legal registration of marriage paperwork with the local
          authority registry office. This is a very quick and simple process and
          can be done prior to your celebration of marriage and wedding
          ceremony. Your marriage will not be legally recognised in the UK until
          you complete this registration of marriage.
          <br />
          <br />
          Your personalised, fun, love-filled, joyful celebrant-led wedding
          ceremony is now totally free of all the dictatorial historical
          constraints that can come with other types of wedding ceremonies.
          Outdoor weddings, festival weddings, beach weddings, the family
          garden, weddings under canvas, barns, stately homes, castles, urban
          chic or simply a wedding at a venue you both love, there is no such
          thing as one perfect wedding ceremony. It is your day and your perfect
          wedding ceremony will the one that reflects your beliefs and
          personalities. I will lovingly craft it with you, for you. Let your
          imagination and your personalities take you wherever you want to go as
          you join together and consolidate your love. I stand proudly in my
          belief that love is love and champion LGBTQ+ equality.
        </Typography>

        <Divider />

        <PriceHeader serviceName={"Wedding"} />

        <Typography>
          Wedding Celebrant Service. £550.00*
          <br />
          <br />
          <ul>
            <li>
              Initial 'getting to know you' face-to-face meeting, telephone or
              video call. We will have a lovely chilled chat so that I can hear
              all about the ceremony of your choice, your story as a couple and
              your hopes and aspirations for your very special day. This will
              give me all that I need to put together a beautiful, meaningful
              and memorable day for both of you, your family and friends. This
              will normally take up to 90 minutes and a follow-up call/video all
              may be required.
            </li>
            <li>
              Ideas and suggestions for writing your own wedding vows and
              symbolic
            </li>
            <li>wedding elements.</li>
            <li>
              Individual or support as a couple with writing your personal
              wedding vows if required.
            </li>
            <li>
              Unlimited one to one telephone support for key members of your
              wedding party. This may include, but is not limited to, help
              choosing readings, coaching and confidence enhancing rehearsal of
              speech delivery etc.
            </li>
            <li>
              Access to a large library of vows, readings, and poems. Support
              sourcing readings etc. This in no way prevents you from adding
              your own choices and these will be incorporated into the ceremony.
            </li>
            <li>
              The writing of the ceremony, including the order of service and a
              first draft copy. Amendments as necessary. This may require
              several telephone/zoom/video calls or email exchanges.
            </li>
            <li>Liaison with your venue.</li>
            <li>
              An outline order of service to promote communication and
              coordination of your special day.
            </li>
            <li>
              An optional full wedding rehearsal at your venue. *there will be
              an additional charge for my time, and travel costs may be
              included, depending on the distance.
            </li>
            <li>
              Delivering the ceremony at a venue of your choice. An extended
              ceremony would incur further pre-agreed costs.
            </li>
            <li>
              Use of Bluetooth enabled wireless PA system. Helpful for readings
              and playing your choice of music at outdoor venues or venues with
              no music delivery system.
            </li>
            <li>
              One printed copy of the ceremony on high-quality paper as a
              memento of your day.
            </li>
            <li>
              Payment is in two stages. A deposit of £165.00 pre-first draft of
              your ceremony script and £385.00 4 weeks before the big day
              itself.
            </li>
            <li>
              DIY Wedding? From £350.00. A bespoke script writing only package
              is available with individual quotes on application.
            </li>
            <li>
              For your marriage to be recognised by law, you must give the
              appropriate notices of marriage and register the legal paperwork.
              In the UK if you are holding a celebrant led wedding ceremony,
              this can easily be done by appointment with a request for a
              "simple ceremony" or a "2 plus 2" ceremony at your local council
              registrar "s office. Council rates for this average at £60.
              Although there is absolutely nothing stopping you from having your
              beautiful ceremony before registering the paperwork, it is
              important to note that your marriage will not be recognised in law
              until the paperwork is completed. I recommend that as a couple you
              make the necessary arrangements to complete the legalities before
              your
            </li>
            <li>
              Celebrant Wedding Ceremony (28 days' notice is required). Please
              contact your local authority for information about legal
              formalities.
            </li>
          </ul>
        </Typography>
      </InfoSection>
    </PageWrapper>
  );
};

export default WeddingsPage;
