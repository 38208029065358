import React from "react";
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Stack,
} from "@mui/material";
import { PAGES, SITE_NAME } from "../../constants";
import { MenuOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import useIsWideScreen from "../../hooks/isWideScreen";
import { Page } from "../../types";

const NavBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const isWideScreen = useIsWideScreen();

  const navigate = useNavigate();

  const pages = Object.keys(PAGES) as Array<Page>;

  return (
    <AppBar position={isWideScreen ? "absolute" : "sticky"}>
      <Container maxWidth="xl">
        <Toolbar>
          <Stack
            display={{ xs: "none", md: "flex" }}
            sx={{ width: "100%" }}
            justifyContent={"center"}
          >
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                margin: "auto",
                my: 1,
                fontWeight: 700,
                letterSpacing: ".3rem",
                textDecoration: "none",
              }}
            >
              {SITE_NAME}
            </Typography>
            <Box
              sx={{
                margin: "auto",
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
              }}
            >
              {pages.map((page) => (
                <Button
                  key={PAGES[page].label}
                  onClick={() => {
                    handleCloseNavMenu();
                    navigate(`/${page}`);
                  }}
                  sx={{ mb: 1, display: "block" }}
                >
                  {PAGES[page].label}
                </Button>
              ))}
            </Box>
          </Stack>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuOutlined />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={PAGES[page].label}
                  onClick={() => {
                    handleCloseNavMenu();
                    navigate(`/${page}`);
                  }}
                >
                  <Typography textAlign="center">
                    {PAGES[page].label}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {SITE_NAME}
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
