import { Paper } from "@mui/material";
import { PropsWithChildren } from "react";

const OpaqueContainer = ({
  children,
  opaque,
}: PropsWithChildren<{ opaque?: boolean }>) => {
  return (
    <Paper
      sx={{
        padding: "20px",
        bgcolor: `rgba(244,247,248,0.${opaque ? "7" : "9"})`,
      }}
    >
      {children}
    </Paper>
  );
};
export default OpaqueContainer;
