import { Page } from "./types";

export const SITE_NAME = "VM Celebrant";
export const PAGES: Record<
  Page,
  {
    route: string;
    label: string;
  }
> = {
  [Page.Home]: {
    route: Page.Home,
    label: "Home",
  },
  [Page.AboutMe]: {
    route: Page.AboutMe,
    label: "About Me",
  },
  [Page.Funerals]: {
    route: Page.Funerals,
    label: "Funerals",
  },
  [Page.Weddings]: {
    route: Page.Weddings,
    label: "Weddings",
  },
  [Page.Namings]: {
    route: Page.Namings,
    label: "Namings",
  },
  [Page.Contact]: {
    route: Page.Contact,
    label: "Contact",
  },
};

export const CONTACT_PHONE = "07866 685053";
export const CONTACT_EMAIL = "vmcelebrant1@gmail.com";
