import { Box, Stack, Typography } from "@mui/material";
import { PageConfig } from "../types";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../../../constants";

const MAX_WIDTH = "273px";

const ServiceCard = ({
  pageConfig: { img, page, content },
}: {
  pageConfig: PageConfig;
}) => {
  const navigate = useNavigate();
  return (
    <Stack>
      <Box
        sx={{ cursor: "pointer", margin: "auto" }}
        onClick={() => {
          navigate(`/${page}`);
        }}
      >
        <img
          src={img}
          alt={`${page}_service`}
          style={{
            width: "auto",
            maxWidth: MAX_WIDTH,
            margin: "auto",
          }}
        />
        <Typography
          className="text-center"
          variant={"h5"}
          style={{ margin: "1em 0" }}
        >
          {PAGES[page].label}
        </Typography>
      </Box>
      <Typography
        className="text-center"
        style={{
          margin: "auto",
          maxWidth: MAX_WIDTH,
        }}
      >
        {content}
      </Typography>
    </Stack>
  );
};

export default ServiceCard;
