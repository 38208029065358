import { Container, Grid, Stack, Typography } from "@mui/material";
import ContactForm from "./ContactForm";
import Section from "../../components/Section";
import PageWrapper from "../../components/PageWrapper";
import ContactSnackbar from "./ContactSnackbar";
import { useState } from "react";
import { ContactResponseState } from "./types";

const ContactPage = () => {
  const [contactResponse, setContactResponse] =
    useState<ContactResponseState>(undefined);
  return (
    <PageWrapper>
      <Section>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Stack justifyContent="center" height="100%">
              <Container maxWidth="sm" >
                <Typography mb={2}>
                  Whether you’re ready to plan your ceremony or just have a few
                  questions, I’m here to help.
                  <br />
                  <br />
                  Please provide as much detail as possible, including the type
                  of service, your preferred date(s), contact information, and
                  any other information you think might be helpful. I look
                  forward to hearing from you!
                </Typography>
              </Container>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <ContactForm setContactResponse={setContactResponse} />
          </Grid>
        </Grid>
      </Section>
      <ContactSnackbar
        contactResponse={contactResponse}
        setContactResponse={setContactResponse}
      />
    </PageWrapper>
  );
};

export default ContactPage;
