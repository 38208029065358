import { Divider, Typography } from "@mui/material";
import InfoSection from "../../components/Section/InfoSection";

import PriceHeader from "../../components/Section/InfoSection/PriceHeader";
import PageWrapper from "../../components/PageWrapper";

const NamingsPage = () => {
  return (
    <PageWrapper>
      <InfoSection title="Namings" buttonText="Begin Your Celebration!">
        <Typography>
          So, it's a religious Christening or nothing right? Well, no! Naming
          ceremonies are the perfect alternative to a traditional religious
          Christening. What a wonderful way to formally introduce and name your
          new baby, to honour those adults whom you have identified as being
          significant influences in their lives as they grow, and to share all
          your hopes and aspirations for your child. Memories are made here.
          <br />
          <br />
          Naming ceremonies are not limited to babies. They can be held for
          children of any age, and are becoming increasingly popular for
          welcoming adopted or step-children into the family. They are also a
          popular choice where a couple both hold different religious beliefs
          and wish to blend some elements of both religions into one ceremony.
          They are also very special for people with a trans experience seeking
          name affirmation.
        </Typography>

        <Divider />

        <PriceHeader serviceName={"Naming"} />

        <Typography>
          Naming Ceremony Celebrant Service £250.00*
          <br />
          <ul>
            <li>
              Initial 'getting to know you' face to face meeting of up to 2
              hours: I will visit to talk with you about the ceremony of your
              choice, and help you to put this together to become a beautiful,
              meaningful day for both of you, your family and friends.
            </li>
            <li>
              Access to a large library of readings and poems. This in no way
              prevents you from adding your own choices and these will be
              incorporated into the ceremony.
            </li>
            <li>
              Contact via telephone, email or text to all guide-parents /
              supporting adults to talk them through the ceremony and support
              them in writing pledges.
            </li>
            <li>
              The writing of the ceremony and a first draft copy. Amendments as
              necessary.
            </li>
            <li>
              Delivering the ceremony up to 1 hour: At a venue of your choice is
              part of your basic costs.
            </li>
            <li>2 printed copies of your ceremony on high quality paper.</li>
            <li>
              Two formal Naming Ceremony Certificates signed by
              guideparents/supporting adults or anybody you identify as having
              played a significant role in the ceremony.
            </li>
          </ul>
        </Typography>
      </InfoSection>
    </PageWrapper>
  );
};

export default NamingsPage;
