import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3f51b5", // Light off-white color
    },
    secondary: {
      main: "#3f51b5", // A deep blue color for contrast
    },
    text: {
      primary: `rgb(66, 66, 66)`,
      secondary: "#666666",
    },
    background: {
      default: "#ffffff",
      paper: "#f9f9f9",
    },
  },
  typography: {
    fontFamily: "Lato, Arial, sans-serif",
    h1: {
      fontFamily: "Cormorant Garamond, serif",
      fontWeight: 600,
      color: "#424242",
    },
    h2: {
      fontFamily: "Cormorant Garamond, serif",
      fontWeight: 600,
      color: "#424242",
    },
    h3: {
      fontFamily: "Cormorant Garamond, serif",
      fontWeight: 500,
      color: "#424242",
    },
    h4: {
      fontFamily: "Cormorant Garamond, serif",
      fontWeight: 500,
      color: "#424242",
    },
    h5: {
      fontFamily: "Cormorant Garamond, serif",
      fontWeight: 400,
      color: "#424242",
    },
    h6: {
      fontFamily: "Cormorant Garamond, serif",
      fontWeight: 400,
      color: "#424242",
    },
    subtitle1: {
      fontFamily: "Lato, Arial, sans-serif",
      fontWeight: 400,
    },
    subtitle2: {
      fontFamily: "Lato, Arial, sans-serif",
      fontWeight: 400,
    },
    body1: {
      fontFamily: "Lato, Arial, sans-serif",
      fontWeight: 400,
    },
    body2: {
      fontFamily: "Lato, Arial, sans-serif",
      fontWeight: 300,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            borderColor: "rgba(63, 81, 181, 0.04)", // Replace with your preferred color
            borderWidth: 2, // Adjust the border width if needed
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
          color: "#333333",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          "& h6": {
            fontFamily: "Cormorant Garamond, serif",
            fontWeight: 600,
            color: "#424242",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontFamily: "Lato, Arial, sans-serif",
          fontWeight: 500,
        },
        contained: {
          backgroundColor: "#3f51b5", // Deep blue
          color: "#ffffff", // White text
          "&:hover": {
            backgroundColor: "#303f9f", // Slightly darker on hover
          },
        },
        outlined: {
          borderColor: "#3f51b5",
          color: "#3f51b5",
          "&:hover": {
            backgroundColor: "rgba(63, 81, 181, 0.04)", // Light blue background on hover
          },
        },
        text: {
          color: "#3f51b5",
          "&:hover": {
            backgroundColor: "rgba(63, 81, 181, 0.04)", // Light blue background on hover
          },
        },
      },
    },
  },
});

export default theme;
