import { Page } from "../../../types";
import { Grid, Typography } from "@mui/material";
import Section from "../../../components/Section";
import funeralImg from "../../../img/funeral_service_card.jpg";
import namingImg from "../../../img/naming_service_card.jpg";
import weddingImg from "../../../img/wedding_service_card.jpg";
import ServiceCard from "./ServiceCard";
import { PageConfig } from "./types";

const PAGE_CONFIGS: Array<PageConfig> = [
  {
    img: namingImg,
    page: Page.Namings,
    content: (
      <Typography>
        Welcome your child or loved one with a meaningful ceremony that
        celebrates new beginnings and cherished connections.
      </Typography>
    ),
  },
  {
    img: weddingImg,
    page: Page.Weddings,
    content: (
      <Typography>
        Celebrate your love story with a bespoke ceremony that captures your
        unique bond, dreams, and journey together.
      </Typography>
    ),
  },
  {
    img: funeralImg,
    page: Page.Funerals,
    content: (
      <Typography>
        Honor a loved one’s memory with a personalized ceremony that reflects
        their life, beliefs, and legacy with dignity and compassion.
      </Typography>
    ),
  },
];

const ServiceCardsSection = () => {
  return (
    <Section>
      <Grid container spacing={2} rowSpacing={{ xs: 12, md: 1 }}>
        {PAGE_CONFIGS.map((pageConfig) => (
          <Grid item xs={12} md={4}>
            <ServiceCard pageConfig={pageConfig} />
          </Grid>
        ))}
      </Grid>
    </Section>
  );
};

export default ServiceCardsSection;
