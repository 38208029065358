import React, { useCallback, useState } from "react";
import {
  TextField,
  MenuItem,
  Button,
  Typography,
  Container,
  Paper,
  Stack,
} from "@mui/material";
import { ContactResponse, ContactResponseState } from "./types";

const contactReasons = [
  "Naming Ceremony",
  "Wedding Ceremony",
  "Funeral Ceremony",
  "Other",
];

function isValidEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

const ContactForm = ({
  setContactResponse,
}: {
  setContactResponse: React.Dispatch<
    React.SetStateAction<ContactResponseState>
  >;
}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    reason: "",
    message: "",
  });
  const [validObj, setValidObj] = useState({
    name: true,
    email: true,
    reason: true,
    message: true,
  });

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target;
      setValidObj((prevData) => ({
        ...prevData,
        [name]: true,
      }));
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    },
    [setFormData, setValidObj]
  );

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      let valid = true;
      const tempValidObj = {
        name: true,
        email: true,
        reason: true,
        message: true,
      };
      if (!formData.name) {
        tempValidObj.name = false;
        valid = false;
      }
      if (!formData.email || !isValidEmail(formData.email)) {
        tempValidObj.email = false;

        valid = false;
      }
      if (!formData.reason) {
        tempValidObj.reason = false;
        valid = false;
      }
      if (!formData.message) {
        tempValidObj.message = false;
        valid = false;
      }

      if (!valid) {
        setValidObj(tempValidObj);
        return;
      }

      setLoading(true);

      fetch("https://backend.vmcelebrant.com/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            setFormData({ name: "", email: "", reason: "", message: "" });
            setContactResponse(ContactResponse.Success);
            return;
          }
          setContactResponse(ContactResponse.Error);
        })
        .catch((err) => {
          setLoading(false);
          setContactResponse(ContactResponse.Error);
        });
    },
    [formData, setContactResponse]
  );

  return (
    <Container maxWidth="sm">
      <Paper
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ padding: 2 }}
      >
        <Typography variant="h4" gutterBottom>
          Get in Contact
        </Typography>
        <TextField
          fullWidth
          required
          margin="normal"
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          error={!validObj.name}
        />
        <TextField
          fullWidth
          required
          margin="normal"
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          error={!validObj.email}
        />
        <TextField
          fullWidth
          required
          margin="normal"
          select
          label="Reason for Contact"
          name="reason"
          value={formData.reason}
          onChange={handleChange}
          error={!validObj.reason}
        >
          {contactReasons.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          required
          margin="normal"
          label="Message"
          name="message"
          multiline
          rows={4}
          value={formData.message}
          onChange={handleChange}
          error={!validObj.message}
        />
        <Stack alignItems="end">
          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Submit
          </Button>
        </Stack>
      </Paper>
    </Container>
  );
};

export default ContactForm;
