import { Typography } from "@mui/material";

const PriceHeader = ({ serviceName }: { serviceName: string }) => {
  return (
    <Typography variant="h4">
      2024 Pricing Structure for {serviceName} Ceremonies
    </Typography>
  );
};

export default PriceHeader;
