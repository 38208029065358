import { Route, Routes as ReactRoutes } from "react-router-dom";
import useScrollToTop from "../hooks/useScrollToTop";
import { Page } from "../types";
import AboutMePage from "./AboutMe";
import FuneralsPage from "./Funerals";
import HomePage from "./Home";
import NamingsPage from "./Namings";
import WeddingsPage from "./Weddings";
import ContactPage from "./Contact";

const Routes = () => {
  useScrollToTop();
  return (
    <ReactRoutes>
      <Route path={Page.AboutMe} element={<AboutMePage />} />
      <Route path={Page.Namings} element={<NamingsPage />} />
      <Route path={Page.Weddings} element={<WeddingsPage />} />
      <Route path={Page.Funerals} element={<FuneralsPage />} />
      <Route path={Page.Contact} element={<ContactPage />} />
      <Route path={'*'} element={<HomePage />} />
    </ReactRoutes>
  );
};

export default Routes;
